import React, { FC } from "react";
import { graphql } from "gatsby";

import Header from "../partials/Header";
import HeroHome from "../partials/HeroHome";
import Process from "../partials/Process";
import FeaturesBlocks from "../partials/FeaturesBlocks";
import Target from "../partials/Target";
import News from "../partials/News";
import Newsletter from "../partials/Newsletter";
import Footer from "../partials/Footer";
import Seo from "../partials/Seo";
import Networks from "../partials/Networks";

const Home: FC<any> = ({ data }) => (
  <>
    <Seo />

    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page sections */}
        <HeroHome />
        <Process />
        <Networks />
        <FeaturesBlocks />
        <Target />
        <News posts={data.allMediumFeed.nodes} />
        <Newsletter />
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  </>
);

export default Home;

export const pageQuery = graphql`
  query {
    allMediumFeed(limit: 3) {
      nodes {
        id
        slug
        title
        description
        thumbnail
        date(formatString: "MMM YYYY")
        link
        tags {
          slug
          name
        }
      }
    }
  }
`;
