import React, { FC } from "react";

import { ReactComponent as NonCustodialIcon } from "../icons/glass/Secure.svg";
import { ReactComponent as OpenSourceIcon } from "../icons/glass/OpenSource.svg";
import { ReactComponent as UserFriendlyIcon } from "../icons/glass/UserFriendly.svg";
import CommonSection from "./CommonSection";

const ProcessContent = [
  {
    Icon: NonCustodialIcon,
    title: "Non-Custodial",
    description:
      "Wigwam is a decentralized wallet." +
      " Users have direct access to crypto funds on the blockchain by using their private keys.",
  },
  {
    Icon: OpenSourceIcon,
    title: "Open source",
    description:
      "Our code is published and open for public review and modifications." +
      " Thus, everyone can check how it is connected to the blockchain," +
      " how it reaches accounts in the networks and processes the transactions.",
  },
  {
    Icon: UserFriendlyIcon,
    title: "User-friendly",
    description:
      "A new way of the browser wallet development:" +
      " full screen is first, activities watch bar," +
      " pleasant experience with the Web3 and much more.",
  },
];

const Process: FC = () => {
  return (
    <CommonSection
      title="Built exclusively for you"
      description={
        <>
          Wigwam is a browser extension that allows you to manage your wallets,{" "}
          <span className="whitespace-nowrap">store and move funds</span>, and
          interact with decentralized applications.
        </>
      }
    >
      <div className="max-w-sm mx-auto grid gap-8 md:grid-cols-3 lg:gap-16 items-start md:max-w-none">
        {ProcessContent.map(({ Icon, title, description }, index) => (
          <div
            className="relative flex flex-col items-center"
            data-aos="fade-up"
            data-aos-delay={200 * index}
            key={title}
          >
            <Icon className="w-14 h-auto mb-7" />
            <h4 className="text-2xl	font-bold	mb-4">{title}</h4>
            <p className="md:text-sm text-center text-brand-secondary">
              {description}
            </p>
          </div>
        ))}
      </div>
    </CommonSection>
  );
};

export default Process;
