import React, { FC } from "react";

import SocialLinks from "./SocialLinks";
import CommonSection from "./CommonSection";
import InstallApp from "./InstallApp";

const UnderLinedSpan: FC = ({ children }) => (
  <span className="relative">
    <span
      className="absolute inset-x-0 bottom-0 h-1 w-full bg-gradient-to-l from-brand-accentone to-brand-accenttwo"
      aria-hidden="true"
    />
    {children}
  </span>
);

const HeroHome: FC = () => {
  return (
    <CommonSection className="!pt-32 md:!pt-40">
      <div className="relative flex flex-col lg:grid lg:grid-cols-2 lg:gap-x-6 lg:gap-y-8">
        {/* Section header */}
        <div className="pb-12 md:pb-16 flex flex-col items-center text-center lg:text-left lg:items-start">
          <h1 className="h1 !text-[2.5rem] mb-4" data-aos="fade-up">
            The most convenient
            <br />
            <span className="whitespace-nowrap">
              <UnderLinedSpan>crypto wallet</UnderLinedSpan>
            </span>
            {" "}
            for{" "}
            <span className="whitespace-nowrap">Web3</span>
          </h1>
          <p
            className="text-xl text-brand-secondary"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            Wigwam lets you{" "}
            <span className="whitespace-nowrap">explore DeFi and NFTs</span>
            <br />
            in a safer, faster{" "}
            <span className="whitespace-nowrap">and modern way</span>.
          </p>
          <p className="text-2xl font-semibold my-6">
            Beta testing is here! 🏕
          </p>
          <InstallApp/>
          <div
            className="mt-5 flex flex-col items-center lg:items-start"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <span className="w-44 flex justify-between items-center sm:text-xl text-white mt-5 mb-4">
              <span
                aria-hidden="true"
                className="w-[3.75rem] h-px bg-brand-tertiary/[.15]"
              />
              and
              <span
                aria-hidden="true"
                className="w-[3.75rem] h-px bg-brand-tertiary/[.15]"
              />
            </span>
            <div className="flex flex-col sm:flex-row items-center">
              <h4 className="sm:text-lg font-bold text-white">
                Follow our updates on:
              </h4>
              <SocialLinks className="mt-2 sm:mt-0 sm:ml-3" />
            </div>
          </div>
        </div>

        {/* Hero image */}
        <div className="lg:absolute lg:top-0 lg:left-1/2">
          <div
            className="hero"
            data-aos="fade-up"
            data-aos-delay="500"
          >
            <div className="hero-wrapper">
            <div
              className="hero-background"
              aria-hidden="true"
            />
            <div className="hero-popup" style={{
              backgroundImage: `url(${
                    require("../images/Interface-popup.jpg").default
                  })`,
            }}/>
            <div className="hero-fullpage" style={{
              backgroundImage: `url(${
                    require("../images/Interface.jpg").default
                  })`,
            }}/>
            </div>
          </div>
        </div>
      </div>
    </CommonSection>
  );
};

export default HeroHome;
