import React, { FC } from "react";
import classNames from "clsx";

import { ReactComponent as OptimismIcon } from "../icons/network/optimism.svg";
import { ReactComponent as PolygonIcon } from "../icons/network/polygon.svg";
import { ReactComponent as ArbitrumIcon } from "../icons/network/arbitrum.svg";
import { ReactComponent as FantomIcon } from "../icons/network/fantom.svg";
import { ReactComponent as EthereumIcon } from "../icons/network/ethereum.svg";
import { ReactComponent as AvalancheIcon } from "../icons/network/avalanche.svg";
import { ReactComponent as HarmonyIcon } from "../icons/network/harmony.svg";
import { ReactComponent as CeloIcon } from "../icons/network/celo.svg";
import { ReactComponent as BscIcon } from "../icons/network/bsc.svg";
import CommonSection from "./CommonSection";

type NetworkCardProps = {
  Icon: FC<{ className?: string }>;
  title: string;
  className?: string;
};

const NetworkCard: FC<NetworkCardProps> = ({ Icon, title, className }) => (
  <div
    className={classNames(
      "absolute",
      "w-[12.5rem] h-12 px-4",
      "flex items-center",
      "bg-brand-tertiary/5",
      "rounded-[.625rem]",
      className
    )}
  >
    <Icon className="mr-3" />
    <h3 className="text-sm font-bold">{title}</h3>
  </div>
);

const Networks: FC = () => {
  const borderClassName = "border border-brand-tertiary/[.03] rounded-full	";
  const borderPositionClassName =
    "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2";

  return (
    <CommonSection className="!py-32 md:!py-20">
      <div className="flex flex-col justify-center items-center text-center relative">
        <NetworkCard
          Icon={OptimismIcon}
          title="Optimism"
          className="top-[-5rem] left-[40%] md:top-[6.25rem] -translate-x-1/2 md:left-[32%] lg:left-[37%]"
        />
        <NetworkCard
          Icon={PolygonIcon}
          title="Polygon (Matic)"
          className="top-[-1.4rem] right-[30%] md:top-[6.25rem] translate-x-1/2 md:right-[32%] lg:right-[37%]"
        />
        <NetworkCard
          Icon={ArbitrumIcon}
          title="Arbitrum"
          className="top-[2rem] left-[30%] md:top-[13rem] -translate-x-1/2 md:left-[14%] lg:left-[17%]"
        />
        <NetworkCard
          Icon={FantomIcon}
          title="Fantom"
          className="top-[5.5rem] right-[40%] md:top-[13rem] translate-x-1/2 md:right-[14%] lg:right-[17%]"
        />
        <NetworkCard
          Icon={EthereumIcon}
          title="Ethereum"
          className="top-[10.5rem] left-[50%] md:top-1/2 -translate-y-1/2 -translate-x-1/2 md:left-[12%] lg:left-[14%]"
        />
        <NetworkCard
          Icon={BscIcon}
          title="Binance"
          className="bottom-[6.9rem] right-[38%] md:bottom-auto md:top-1/2 -translate-y-1/2 translate-x-1/2 md:right-[12%] lg:right-[14%]"
        />
        <NetworkCard
          Icon={HarmonyIcon}
          title="Harmony (One)"
          className="bottom-[4.9rem] left-[40%] md:bottom-[13rem] -translate-x-1/2 md:left-[14%] lg:left-[17%]"
        />
        <NetworkCard
          Icon={CeloIcon}
          title="Celo"
          className="bottom-[1.4rem] right-[27%] md:bottom-[13rem] translate-x-1/2 md:right-[14%] lg:right-[17%]"
        />
        <NetworkCard
          Icon={AvalancheIcon}
          title="Avalanche"
          className="bottom-[-2rem] md:bottom-[6.25rem] -translate-x-1/2 left-1/2"
        />
        <div
          className={classNames(borderClassName, "w-[45.125rem] h-[45.125rem]")}
        />
        <div
          className={classNames(
            borderClassName,
            borderPositionClassName,
            "w-[30.875rem] h-[30.875rem]"
          )}
        />
        <div
          className={classNames(
            borderClassName,
            borderPositionClassName,
            "w-[19.625rem] h-[19.625rem]"
          )}
        />
        <div className="w-full md:w-auto absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-xl">
          <p className="mb-3 md:mb-7 text-transparent bg-clip-text bg-gradient-to-r from-brand-accenttwo to-brand-accentone tracking-wide">
            Even more versatile
          </p>
          <h2 className="mb-3 text-[2rem] font-bold">Multi-network</h2>
          <p className="mb-5">
            With Wigwam, you can interact with different networks
            <br />
            <strong>at the same time!</strong>
          </p>
          <p>
            Currently, we aim to work with Ethereum
            <br />
            and other EVM-based networks.
          </p>
        </div>
      </div>
    </CommonSection>
  );
};

export default Networks;
