import React, { FC } from "react";

import { ReactComponent as SecureIcon } from "../icons/glass/Secure.svg";
import { ReactComponent as MultiChainIcon } from "../icons/glass/MultiChain.svg";
import { ReactComponent as AppsIcon } from "../icons/glass/Apps.svg";
import { ReactComponent as MetamaskIcon } from "../icons/glass/Metamask.svg";
import { ReactComponent as LedgerIcon } from "../icons/glass/Ledger.svg";
import { ReactComponent as FullPageIcon } from "../icons/glass/FullPage.svg";
import CommonSection from "./CommonSection";

type FeatureItemProps = {
  Icon: FC<{ className?: string }>;
  title: string;
};

const FeaturesContent: FeatureItemProps[] = [
  {
    Icon: SecureIcon,
    title: "Secure, private,\nnon-custodial wallet",
  },
  {
    Icon: MultiChainIcon,
    title: "Multi-chain support\nfrom the box",
  },
  {
    Icon: AppsIcon,
    title: "Specialized for work\nwith dApps",
  },
  {
    Icon: MetamaskIcon,
    title: "MetaMask\ncompatible mode",
  },
  {
    Icon: LedgerIcon,
    title: "Ledger Nano and\nTrezor support",
  },
  {
    Icon: FullPageIcon,
    title: "Popup and full page\nmodes",
  },
];

const FeaturesBlocks: FC = () => {
  return (
    <CommonSection title="Wigwam main features:">
      <div
        className="max-w-sm mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-3 lg:gap-16 items-start md:max-w-2xl lg:max-w-none"
        data-aos-id-blocks
      >
        {FeaturesContent.map(({ Icon, title }) => (
          <div
            key={title}
            className="relative flex flex-col items-center"
            data-aos="fade-up"
            data-aos-anchor="[data-aos-id-blocks]"
          >
            <Icon className="w-14 h-auto mb-7" />
            <h3 className="h4 text-xl whitespace-pre-line text-center">
              {title}
            </h3>
          </div>
        ))}
      </div>
    </CommonSection>
  );
};

export default FeaturesBlocks;
