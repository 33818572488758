import React, { FC } from "react";

import CommonSection from "./CommonSection";
import BlogArticle from "./BlogArticle";

type NewsProps = {
  posts: any[];
};

const News: FC<NewsProps> = ({ posts }) => {
  if (!posts || posts.length < 1) {
    return <></>;
  }

  return (
    <CommonSection
      title={
        <>
          Refreshing news for users,{" "}
          <span className="sm:whitespace-nowrap">developers and designers</span>
        </>
      }
    >
      <div className="max-w-sm mx-auto md:max-w-none">
        <div className="grid gap-12 md:grid-cols-3 md:gap-x-6 md:gap-y-8 items-start">
          {posts.map((post) => (
            <BlogArticle
              key={post.id}
              link={post.link}
              image={post.thumbnail}
              tags={post.tags}
              title={post.title}
              subtitle={post.description}
              date={post.date}
            />
          ))}
        </div>
      </div>
    </CommonSection>
  );
};

export default News;
