import React, { FC } from "react";
import CommonSection from "./CommonSection";

const TargetContent = [
  {
    title: "Full page mode",
    description: "All important info is in one place",
  },
  {
    title: "Transaction watch bar",
    description:
      "Check the status of your transactions and manage them more conveniently",
  },
  {
    title: "Portfolio price tracking",
    description: "Check your token value instantly",
  },
  {
    title: "Multi-accounting",
    description: "Create as many accounts(wallets) as you want for any needs",
  },
  {
    title: "NFT tab",
    description: "A convenient way to store your collectibles",
  },
  {
    title: "DApp approval tab",
    description:
      "Have full control over your wallet interactions with the dapps",
  },
];

const Target: FC = () => {
  return (
    <CommonSection
      title="Amazing user experience"
      description={
        <>
          You need to try Wigwam only once, and you will never return to your
          old wallets.
          <br />
          Some of the features:
        </>
      }
    >
      <div className="grid gap-20" data-aos-id-target>
        {/* Item */}
        <div className="lg:grid lg:grid-cols-12 lg:gap-6 items-center">
          {/* Image */}
          <div
            className="max-w-xl lg:max-w-none lg:w-full mx-auto lg:col-span-6 mb-8 lg:mb-0 lg:rtl"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-anchor="[data-aos-id-target]"
          >
            <img
              className="hidden lg:block mx-auto lg:max-w-none"
              src={require("../images/amazing-ux.png").default}
              width="740"
              height="600"
              alt="Wigwam Features"
            />
            <img
              className="lg:hidden mx-auto"
              src={require("../images/amazing-ux-2.png").default}
              width="740"
              height="600"
              alt="Wigwam Features"
            />
          </div>

          {/* Content */}
          <div className="max-w-xl lg:max-w-none lg:w-full mx-auto lg:col-span-6">
            <div className="lg:pl-12 xl:pl-16">
              {TargetContent.map(({ title, description }, index) => (
                <div
                  data-aos="fade-left"
                  data-aos-delay={200 * (index + 1)}
                  data-aos-anchor="[data-aos-id-target]"
                  key={title}
                  className="mb-6 last:mb-0"
                >
                  <h4 className="h4 mb-2">
                    <span
                      className={(() => {
                        const arr = [
                          "text-purple-600",
                          "text-green-500",
                          "text-pink-500",
                          "text-yellow-500",
                          "text-indigo-500",
                          "text-red-500",
                        ];
                        return arr[index % arr.length];
                      })()}
                    >
                      .
                    </span>{" "}
                    {title}
                  </h4>
                  <p className="text-lg text-brand-tertiary">{description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </CommonSection>
  );
};

export default Target;
